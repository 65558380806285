<template>
  <section class="section preferences">
    <base-title :level="1">
      Preferences
    </base-title>
    <form
      class="form"
      method="POST"
      @submit.prevent="submitForm"
    >
      <base-title :level="2">
        AI Scheduling
      </base-title>

      <base-paragraph>
        Let our ai do the work for you. We select new questions based on the answers given.
      </base-paragraph>

      <fieldset class="form__fieldset">
        <form-group>
          <form-toggle
            v-model="form.ai_enabled"
            :loading="$apollo.queries.user.loading"
          >
            <template v-if="form.ai_enabled">
              <base-icon
                name="flash-outline"
                color="#81d119"
                size="26"
              /> AI Enabled
            </template>
            <template v-else>
              <base-icon
                name="flash-off-outline"
                size="26"
              /> AI Disabled
            </template>
          </form-toggle>
        </form-group>
      </fieldset>

      <base-title :level="2">
        Question Scheduling
      </base-title>

      <base-paragraph>
        Choose a day on which we send a question every week:
      </base-paragraph>

      <fieldset class="form__fieldset preferences__radio-buttons">
        <form-radio-button-group>
          <form-radio-button
            v-for="day in days"
            :key="day.value"
            v-model="form.schedule_day"
            :value="day.value"
            size="small"
            :loading="$apollo.queries.user.loading"
          >
            {{ day.name }}
          </form-radio-button>
        </form-radio-button-group>
      </fieldset>

      <base-paragraph>
        Choose a time period in which we send the questions:
      </base-paragraph>

      <fieldset class="form__fieldset preferences__radio-buttons">
        <form-radio-button-group>
          <form-radio-button
            v-for="period in schedulePeriods"
            :key="period.id"
            v-model="form.schedule_period_id"
            :value="period.id"
            size="large"
            :loading="$apollo.queries.user.loading"
          >
            <div>
              {{ period.name }}
            </div>
            <div class="preferences__time">
              ({{ period.from }} - {{ period.to }})
            </div>
          </form-radio-button>
        </form-radio-button-group>
      </fieldset>

      <base-title :level="2">
        Giphy URL
      </base-title>

      <base-paragraph>
        Find a gif on
        <a
          href="https://giphy.com/"
          target="_blank"
          title="Giphy.com"
          class="link"
        >giphy.com</a>
        that you like, press 'Copy Link' and copy the 'GIF Link' url into this field.
      </base-paragraph>

      <fieldset class="form__fieldset">
        <form-input
          v-model="form.giphy"
          placeholder="Enter a giphy URL"
          :loading="$apollo.queries.user.loading"
          :invalid="form.errors.has('giphy')"
          :errors="form.errors.get('giphy')"
          @input="form.errors.clear('giphy')"
        />
      </fieldset>

      <base-group
        class="form__actions"
      >
        <base-button
          :loading="!!isSubmitting"
          :disabled="isSubmitDisabled"
          label="Save Changes"
          icon="save-outline"
          @click="submitForm"
        />
      </base-group>
    </form>
  </section>
</template>

<script>
/* Import Queries */
import USER_QUERY from '@/graphql/queries/User.gql';
import SCHEDULE_PERIODS_QUERY from '@/graphql/queries/SchedulePeriods.gql';

/* Import Mutations */
import UPDATE_USER_MUTATION from '@/graphql/mutations/UpdateUser.gql';

/* Import Classes and Helpers */
import Form from '@/utils/Form';
import { notifyFormSuccess, notifyFormError } from '@/utils/notifications';

export default {
  data() {
    return {
      id: this.$root.$data.userId,
      form: new Form({
        ai_enabled: false,
        schedule_day: null,
        schedule_period_id: '',
        giphy: '',
      }),
      days: [
        { name: 'Mo', value: 1 },
        { name: 'Tu', value: 2 },
        { name: 'We', value: 3 },
        { name: 'Th', value: 4 },
        { name: 'Fr', value: 5 },
        { name: 'Sa', value: 6 },
        { name: 'Su', value: 7 },
      ],
      isSubmitting: 0,
    };
  },
  apollo: {
    user: {
      query: USER_QUERY,
      update(data) {
        if (Object.keys(data).length > 0 && data.constructor === Object) {
          this.form.set({
            ai_enabled: data.user.ai_enabled,
            schedule_day: data.user.schedule_day,
            schedule_period_id: data.user.schedule_period.id,
            giphy: data.user.giphy,
          });
        }
      },
    },
    schedulePeriods: {
      query: SCHEDULE_PERIODS_QUERY,
    },
  },
  computed: {
    isSubmitDisabled() {
      return this.$apollo.queries.user.loading
        || this.form.isDefault
        || this.form.errors.any();
    },
  },
  methods: {
    async submitForm() {
      this.isSubmitting += 1;
      // GraphQL Mutation
      this.$apollo
        .mutate({
          mutation: UPDATE_USER_MUTATION,
          variables: { id: this.id, ...this.form.data },
        })
        .then(() => {
          // Result
          notifyFormSuccess('Your preferences have been updated.');
        })
        .catch((error) => {
          // Error
          notifyFormError();
          this.form.errors.record(error);
        })
        .finally(() => {
          this.isSubmitting -= 1;
        });
    },
    resetForm() {
      this.form.reset();
    },
  },
};
</script>

<style lang="scss" scoped>
.preferences {
  &__radio-buttons {
    max-width: 592px;
  }

  &__time {
    font-weight: normal;
  }
}
</style>
