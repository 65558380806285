<template>
  <div class="validator">
    <p class="paragraph">
      Your new password must be at least 8 characters long and should contain:
    </p>

    <password-validator-item
      label="One lowercase letter"
      :valid="containsLowerCase"
    />
    <password-validator-item
      label="One uppercase letter"
      :valid="containsUpperCase"
    />
    <password-validator-item
      label="One number"
      :valid="containsNumber"
    />
  </div>
</template>

<script>
/* Import Components */
import PasswordValidatorItem from './PasswordValidatorItem.vue';

export default {
  components: {
    PasswordValidatorItem,
  },
  props: {
    password: {
      type: String,
      default: '',
    },
  },
  computed: {
    valid() {
      return this.containsLowerCase && this.containsUpperCase && this.containsNumber;
    },
    containsLowerCase() {
      return (/[a-z]/.test(this.password));
    },
    containsUpperCase() {
      return /[A-Z]/.test(this.password);
    },
    containsNumber() {
      return /\d/.test(this.password);
    },
  },
  watch: {
    valid(valid) {
      if (valid) {
        this.$emit('valid');
      } else {
        this.$emit('invalid');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.validator {
  margin-top: -8px;
}
</style>
