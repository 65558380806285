<template>
  <div class="avatar-uploader">
    <transition name="fade-droparea">
      <label
        v-show="!uploadedImage"
        for="uploader"
        class="droparea"
        :class="{ 'droparea--hovering': isHovering }"
        @drop.prevent="onUploaderDrop"
        @dragover.prevent="onUploadedDragOver"
        @dragleave.prevent="onUploaderDragLeave"
      >
        <div class="image">
          <img
            v-show="imageUrl"
            :src="imageUrl"
          >
        </div>
        <input
          id="uploader"
          ref="file"
          type="file"
          class="input"
          @change="onFileChange"
        >
        <base-icon
          v-if="!value"
          name="upload-outline"
          class="empty-icon"
          size="56"
        />
      </label>
    </transition>

    <transition name="fade-cropper">
      <div
        v-show="uploadedImage"
        class="cropper"
      >
        <vue-croppie
          ref="croppie"
          :viewport="{ width: 180, height: 180, type: 'circle' }"
          :boundary="{ width: 180, height: 180 }"
          :enable-resize="false"
          @update="onCropperUpdate"
          @result="onCropperResult"
        />
      </div>
    </transition>
  </div>
</template>

<script>
import { debounce } from 'lodash';

export default {
  props: {
    value: {
      type: Blob,
      default: null,
    },
    imageUrl: {
      type: String,
      default: null,
    },
    width: {
      type: Number,
      default: 400,
    },
    height: {
      type: Number,
      default: 400,
    },
  },
  data() {
    return {
      uploadedImage: null,
      isHovering: false,
    };
  },
  watch: {
    value(value) {
      // Reset everything when the value is cleared
      if (!value) {
        // Clear file input
        this.$refs.file.value = '';
        // Clear uploaded image
        this.uploadedImage = null;
        // Clear croppie
        // this.$refs.croppie.refresh();
      }
    },
  },
  methods: {
    onUploadedDragOver() {
      // The user is hovering a file over the drop area
      this.isHovering = true;
    },
    onUploaderDragLeave() {
      // No file is hovering over the drop area anymore
      this.isHovering = false;
    },
    onUploaderDrop(event) {
      // No file is hovering over the drop area anymore
      this.isHovering = false;
      // Get selected files
      const files = event.target.files || event.dataTransfer.files;
      // Check if any files were selected
      if (!files.length) return;
      // Create image from file, but only get the first selected
      this.createImage(files[0]);
    },
    onFileChange(event) {
      const files = event.target.files || event.dataTransfer.files;
      // Check if any files are selected
      if (!files.length) return;
      // Create image from file, but only get the first selected
      this.createImage(files[0]);
    },
    onCropperUpdate() {
      // Image has been updated, output result automatically
      this.result();
    },
    onCropperResult(image) {
      // Image has been cropped, emit update event
      this.$emit('input', image);
    },
    createImage(file) {
      const reader = new FileReader();
      const vm = this;
      // Wait for the file upload to finish
      reader.onload = (event) => {
        // Save uploaded image
        vm.uploadedImage = event.target.result;
        // Put uploaded image into cropper
        vm.$refs.croppie.bind({
          url: event.target.result,
        });
      };
      // Read selected file content
      reader.readAsDataURL(file);
    },
    // eslint-disable-next-line func-names
    result: debounce(function () {
      // Output croppie result
      this.$refs.croppie.result({
        type: 'blob',
        size: { width: this.width, height: this.height },
        circle: false,
      });
    }),
  },
};
</script>

<style lang="scss" scoped>
.avatar-uploader {
  position: relative;
  width: 180px;
  height: 180px;
}

.droparea {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
  color: $color-white;
  background-color: $color-gray50;
  border-radius: 100%;
  cursor: pointer;
  transition: background-color 300ms;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: $color-white;
    opacity: 0;
    transition: opacity 200ms;
    content: '';
    pointer-events: none;
  }

  &:hover,
  &--hovering {
    background-color: $color-primary;

    .image {
      opacity: 0;
    }
  }
}

.input {
  display: none;
}

.image {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  border-radius: 100%;
  transition: opacity 300ms;

  img {
    width: 100%;
    height: 100%;
  }
}

.cropper {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.fade-droparea-enter-active,
.fade-droparea-leave-active {
  transition: border-radius 300ms;
}

.fade-droparea-enter,
.fade-droparea-leave-to {
  border-radius: 0;
}

.fade-cropper-enter-active,
.fade-cropper-leave-active {
  transition: opacity 300ms, border-radius 300ms;
}

.fade-cropper-enter,
.fade-cropper-leave-to {
  border-radius: 100px;
  opacity: 0;
}
</style>

<style lang="scss">
.croppie-container {
  position: relative;

  &:hover {
    .cr-slider-wrap {
      opacity: 1;
      transition-delay: 0ms;
    }
  }

  .cr-slider-wrap {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    padding: 5px 10px 0 10px;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 20px;
    opacity: 0;
    transition: opacity 200ms 200ms;
  }

  .cr-viewport,
  .cr-resizer {
    border: none !important;
  }
}
</style>
