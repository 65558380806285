<template>
  <div
    class="validator-item"
    :class="{ 'validator-item--valid': valid }"
  >
    <base-icon
      class="icon"
      name="checkmark-circle-outline"
    />
    <span class="label">{{ label }}</span>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: '',
    },
    valid: {
      type: Boolean,
      default: false,
    },
  },

};
</script>

<style lang="scss" scoped>
.validator-item {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  color: $color-gray70;

  &--valid {
    color: $color-primary;
    font-weight: bold;
  }
}

.icon {
  margin-right: 8px;
}
</style>
