<template>
  <section class="section account">
    <base-title :level="1">
      Account
    </base-title>
    <form
      class="form"
      method="POST"
      @submit.prevent="submitForm"
    >
      <base-title :level="2">
        Company Logo
      </base-title>

      <fieldset class="form__fieldset">
        <div class="avatar-columns">
          <div class="avatar-column avatar-column--image">
            <avatar-uploader
              v-model="avatarBlob"
              :image-url="avatar"
            />
          </div>

          <div class="avatar-column avatar-column--details">
            <h4 class="title title--h3">
              Upload Your Company Logo
            </h4>

            <p class="paragraph">
              The uploaded image should be at least 400px by 400px.
            </p>

            <form-errors :errors="avatarErrors.get('avatar')" />

            <base-group
              class="form__actions"
              spacing="small"
            >
              <base-button
                type="primary"
                label="Save"
                icon="save-outline"
                :disabled="!avatarBlob"
                :loading="!!isUploading"
                @click="uploadAvatar"
              />

              <base-button
                v-if="avatarBlob"
                type="primary"
                label="Cancel"
                link
                @click="resetAvatar"
              />

              <base-button
                v-else-if="avatar"
                :disabled="!avatar"
                type="danger"
                label="Delete"
                icon="trash-2-outline"
                link
                :loading="!!isClearing"
                @click="clearAvatar"
              />
            </base-group>
          </div>
        </div>
      </fieldset>

      <base-title :level="2">
        Basic Information
      </base-title>

      <fieldset class="form__fieldset">
        <form-group>
          <form-input
            v-model="form.company_name"
            name="company_name"
            label="Company Name"
            placeholder="Company Name"
            :loading="$apollo.queries.user.loading"
            :disabled="!!isSubmitting"
            :invalid="form.errors.has('company_name')"
            :errors="form.errors.get('company_name')"
            @input="form.errors.clear('company_name')"
          />

          <form-input
            v-model="form.email"
            name="email"
            label="E-Mail Address"
            placeholder="E-Mail Address"
            :loading="$apollo.queries.user.loading"
            :disabled="!!isSubmitting"
            :invalid="form.errors.has('email')"
            :errors="form.errors.get('email')"
            @input="form.errors.clear('email')"
          />
        </form-group>

        <form-group>
          <form-input
            v-model="form.first_name"
            name="first_name"
            label="First Name"
            placeholder="First Name"
            :loading="$apollo.queries.user.loading"
            :disabled="!!isSubmitting"
            :invalid="form.errors.has('first_name')"
            :errors="form.errors.get('first_name')"
            @input="form.errors.clear('first_name')"
          />

          <form-input
            v-model="form.last_name"
            name="last_name"
            label="Last Name"
            placeholder="Last Name"
            :loading="$apollo.queries.user.loading"
            :disabled="!!isSubmitting"
            :invalid="form.errors.has('last_name')"
            :errors="form.errors.get('last_name')"
            @input="form.errors.clear('last_name')"
          />
        </form-group>
      </fieldset>

      <base-group
        class="form__actions"
      >
        <base-button
          :loading="!!isSubmitting"
          :disabled="isSubmitDisabled"
          label="Save Changes"
          icon="save-outline"
          @click="submitForm"
        />
      </base-group>
    </form>
  </section>
</template>

<script>
/* Import Queries */
import USER_QUERY from '@/graphql/queries/User.gql';

/* Import Mutations */
import UPDATE_USER_MUTATION from '@/graphql/mutations/UpdateUser.gql';

/* Import Components */

/* Import Classes and Helpers */
import Form from '@/utils/Form';
import FormErrors from '@/utils/FormErrors';
import { notifyFormSuccess, notifyFormError } from '@/utils/notifications';
import AvatarUploader from './AvatarUploader.vue';

export default {
  components: {
    AvatarUploader,
  },
  data() {
    return {
      id: this.$root.$data.userId,
      form: new Form({
        email: '',
        company_name: '',
        first_name: '',
        last_name: '',
      }),
      isSubmitting: 0,
      avatarBlob: null,
      avatar: null,
      avatarErrors: new FormErrors(),
      isUploading: 0,
      isClearing: 0,
    };
  },
  apollo: {
    user: {
      query: USER_QUERY,
      update(data) {
        if (Object.keys(data).length > 0 && data.constructor === Object) {
          this.form.set({
            email: data.user.email,
            company_name: data.user.company_name,
            first_name: data.user.first_name,
            last_name: data.user.last_name,
          });
          this.avatar = data.user.avatar;
        }
      },
    },
  },
  computed: {
    isSubmitDisabled() {
      return this.$apollo.queries.user.loading
        || this.form.isDefault
        || this.form.errors.any();
    },
  },
  methods: {
    async submitForm() {
      this.isSubmitting += 1;
      // GraphQL Mutation
      this.$apollo
        .mutate({
          mutation: UPDATE_USER_MUTATION,
          variables: { id: this.id, ...this.form.data },
        })
        .then(() => {
          // Result
          notifyFormSuccess('Your account details have been updated.');
        })
        .catch((error) => {
          // Error
          notifyFormError();
          this.form.errors.record(error);
        })
        .finally(() => {
          this.isSubmitting -= 1;
        });
    },
    async uploadAvatar() {
      this.isUploading += 1;
      // GraphQL Mutation
      this.$apollo
        .mutate({
          mutation: UPDATE_USER_MUTATION,
          variables: { id: this.id, avatar: this.avatarBlob },
        })
        .then(() => {
          // Result, save image in local storage
          const reader = new FileReader();
          reader.readAsDataURL(this.avatarBlob);
          reader.onloadend = () => {
            localStorage.setItem('avatar', reader.result);
            // Clear avatar blob
            this.avatarBlob = null;
          };
          // Show success notificatoin
          notifyFormSuccess('Your company logo has been set.');
        })
        .catch((error) => {
          // Error
          this.avatarErrors.record(error);
          notifyFormError('Your company logo could not be saved.');
        })
        .finally(() => {
          this.isUploading -= 1;
        });
    },
    async clearAvatar() {
      this.isClearing += 1;
      // GraphQL Mutation
      this.$apollo
        .mutate({
          mutation: UPDATE_USER_MUTATION,
          variables: { id: this.id, avatar: null },
        })
        .then(() => {
          // Result, clear image from local storage
          localStorage.removeItem('avatar');
          // Clear avatar blob
          this.avatarBlob = null;
          // Show success notificatoin
          notifyFormSuccess('Your company logo has been cleared.');
        })
        .catch(() => {
          // Error
          notifyFormError('An error has occured clearing your company logo.');
        })
        .finally(() => {
          this.isClearing -= 1;
        });
    },
    resetForm() {
      this.form.reset();
    },
    resetAvatar() {
      this.avatarBlob = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.avatar-columns {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}

.avatar-column {
  &--image {
    margin-right: 32px;
  }
}
</style>
