<template>
  <section class="section">
    <base-title :level="1">
      Security
    </base-title>

    <form
      method="POST"
      class="form"
      @submit.prevent="submitForm"
      @keydown="form.errors.clear($event.target.name)"
    >
      <base-title :level="2">
        Update Password
      </base-title>

      <fieldset class="form__fieldset">
        <form-input
          v-model="form.password_current"
          type="password"
          name="password_current"
          label="Current Password"
          :disabled="!!isSubmitting"
          :invalid="form.errors.has('password_current')"
          :errors="form.errors.get('password_current')"
          @input="form.errors.clear('password_current')"
        />
      </fieldset>

      <base-group spacing="large">
        <fieldset class="form__fieldset">
          <form-input
            v-model="form.password"
            type="password"
            name="password"
            label="New Password"
            :disabled="!!isSubmitting"
            :invalid="form.errors.has('password')"
            :errors="form.errors.get('password')"
            @input="form.errors.clear('password');form.errors.clear('password_confirmation');"
          />

          <form-input
            v-model="form.password_confirmation"
            type="password"
            name="password_confirmation"
            label="Confirm New Password"
            :disabled="!!isSubmitting"
            :invalid="form.errors.has('password_confirmation')"
            :errors="form.errors.get('password_confirmation')"
            @input="form.errors.clear('password_confirmation');form.errors.clear('password');"
          />
        </fieldset>

        <password-validator
          :password="form.password"
          @valid="isValid = true"
          @invalid="isValid = false"
        />
      </base-group>
      <base-group
        class="form__actions"
      >
        <base-button
          :loading="!!isSubmitting"
          :disabled="isSubmitDisabled"
          label="Save Changes"
          icon="save-outline"
          @click="submitForm"
        />
      </base-group>
    </form>
  </section>
</template>

<script>
/* Import Mutations */
import UPDATE_USER_MUTATION from '@/graphql/mutations/UpdateUser.gql';

/* Import Components */

/* Import Classes and Helpers */
import Form from '@/utils/Form';
import { notifyFormSuccess, notifyFormError } from '@/utils/notifications';
import PasswordValidator from './PasswordValidator.vue';

export default {
  components: {
    PasswordValidator,
  },
  data() {
    return {
      id: this.$root.$data.userId,
      form: new Form({
        password_confirmation: '',
        password_current: '',
        password: '',
      }),
      isSubmitting: 0,
      isValid: false,
    };
  },
  computed: {
    isSubmitDisabled() {
      return !this.isValid || this.form.isDefault || this.form.errors.any();
    },
  },
  methods: {
    async submitForm() {
      this.isSubmitting += 1;
      // GraphQL Mutation
      this.$apollo
        .mutate({
          mutation: UPDATE_USER_MUTATION,
          variables: { id: this.id, ...this.form.data },
        })
        .then(() => {
          // Result
          this.form.clear();
          notifyFormSuccess('Your password has been updated.');
        })
        .catch((error) => {
          // Error
          notifyFormError();
          this.form.errors.record(error);
        })
        .finally(() => {
          this.isSubmitting -= 1;
        });
    },
    resetForm() {
      this.form.reset();
    },
  },
};
</script>

<style lang="scss" scoped></style>
