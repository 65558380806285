<template>
  <base-page
    class="account"
    title="Settings"
  >
    <template
      #aside
    >
      <ul class="menu-list">
        <li>
          <base-menu-item
            to="#account"
            label="Account"
          />
        </li>
        <li>
          <base-menu-item
            to="#security"
            label="Security"
          />
        </li>
        <li>
          <base-menu-item
            to="#preferences"
            label="Preferences"
          />
        </li>
        <li>
          <base-menu-item
            to="#notifications"
            label="Notifications"
          />
        </li>
        <li>
          <base-menu-item
            to="#billing"
            label="Plan & Billing"
          />
        </li>
      </ul>
    </template>

    <template
      #default
    >
      <base-card id="account">
        <settings-account />
      </base-card>
      <base-card id="security">
        <settings-security />
      </base-card>
      <base-card id="preferences">
        <settings-preferences />
      </base-card>
      <base-card id="notifications">
        <settings-notifications />
      </base-card>
      <base-card id="billing">
        <settings-billing />
      </base-card>
    </template>
  </base-page>
</template>

<script>
import SettingsAccount from '@/components/modules/settings/SettingsAccount.vue';
import SettingsSecurity from '@/components/modules/settings/SettingsSecurity.vue';
import SettingsPreferences from '@/components/modules/settings/SettingsPreferences.vue';
import SettingsNotifications from '@/components/modules/settings/SettingsNotifications.vue';
import SettingsBilling from '@/components/modules/settings/SettingsBilling.vue';

export default {
  components: {
    SettingsAccount,
    SettingsSecurity,
    SettingsPreferences,
    SettingsNotifications,
    SettingsBilling,
  },
  data() {
    return {
      tab: 'company',
    };
  },
};
</script>

<style lang="scss" scoped>
.menu-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
</style>
